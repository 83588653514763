import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'
import useDimensions from '../../hooks/useDimensions'
import { TreeLink, TreeNode } from '../../state/Tree'

const NODE_RADIUS = 19
const NODE_RADIUS_HOVERED = 24
const NODE_BORDER_WIDTH = '3px'
const NODE_TEXT_FONT_SIZE = 18
const NODE_TEXT_COLOR = '#000'
const NODE_TEXT_COLOR_HOVERED = '#FFF'
const NODE_BACKGROUND_COLOR = '#FFF'
const NODE_BACKGROUND_COLOR_HOVERED = '#000'
const EDGE_COLOR = '#000'
const EDGE_WIDTH = '3px'

export interface ITree {
  descendants: TreeNode[]
  links: TreeLink[]
  isRightPaneOpen: boolean
  className?: string
}

const getTextShift = (text: string): number => {
  if (!text || text.length === 0) {
    return 0
  } else if (text.length === 1) {
    return 4
  } else if (text.length === 2) {
    return 5.5
  } else if (text.length === 3) {
    return 6
  }
  return 0
}

const Tree: React.FC<ITree> = ({
  descendants,
  links,
  isRightPaneOpen,
  className = '',
}) => {
  const { windowWidth, windowHeight, treeAreaWidth, treeAreaHeight } =
    useDimensions(isRightPaneOpen)
  const d3Container = useRef(null)
  useEffect(() => {
    if (d3Container.current && descendants && descendants.length) {
      const rootSvg = d3.select('svg')
      rootSvg.selectAll('*').remove()
      const containerGroup = rootSvg
        .append('g')
        .attr('transform', `translate(${0}, ${windowHeight * 0.07})`)

      containerGroup
        .selectAll('line')
        .data(links)
        .join(
          enter => {
            const line = enter
              .append('line')
              .style('stroke', EDGE_COLOR)
              .style('stroke-width', EDGE_WIDTH)
              .attr('x1', d => d.source.x)
              .attr('y1', d => d.source.y)
              .attr('x2', d => d.target.x)
              .attr('y2', d => d.target.y)
            return line
          },
          undefined,
          exit => exit.remove()
        )

      containerGroup
        .selectAll('circle')
        .data(descendants)
        .join(
          enter => {
            const group = enter
              .append('g')
              .attr('data-node-id', d => d.data.id)
              .attr('transform', d => `translate(${d.x}, ${d.y})`)

            group
              .append('circle')
              .style('stroke-width', NODE_BORDER_WIDTH)
              .style('stroke', EDGE_COLOR)
              .style('fill', NODE_BACKGROUND_COLOR)
              .style('cursor', 'pointer')
              .attr('r', NODE_RADIUS)
              .on('mouseenter', function () {
                d3.select(this.parentElement)
                  .select('text')
                  .style('fill', NODE_TEXT_COLOR_HOVERED)
                d3.select(this)
                  .transition()
                  .duration(100)
                  .style('fill', NODE_BACKGROUND_COLOR_HOVERED)
                  .attr('r', NODE_RADIUS_HOVERED)
              })
              .on('mouseout', function () {
                d3.select(this.parentElement)
                  .select('text')
                  .style('fill', NODE_TEXT_COLOR)
                d3.select(this)
                  .transition()
                  .duration(100)
                  .style('fill', NODE_BACKGROUND_COLOR)
                  .attr('r', NODE_RADIUS)
              })
            group
              .append('text')
              .style(
                'font-family',
                'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace'
              )
              .style('fill', NODE_TEXT_COLOR)
              .style('cursor', 'pointer')
              .attr('font-size', `${NODE_TEXT_FONT_SIZE}px`)
              .attr('dx', d => (d.data.value.length === 1 ? -4 : -6))
              .attr('dy', d => 5)
              .attr('text-anchor', 'middle')
              .attr(
                'transform',
                d => `translate(${getTextShift(d.data?.value)}, 0)`
              )
              .text(d => `${d?.data.value}`)
              .on('mouseenter', function () {
                d3.select(this).style('fill', NODE_TEXT_COLOR_HOVERED)
                d3.select(this.parentElement)
                  .select('circle')
                  .transition()
                  .duration(100)
                  .style('fill', NODE_BACKGROUND_COLOR_HOVERED)
                  .attr('r', NODE_RADIUS_HOVERED)
              })
              .on('mouseout', function () {
                d3.select(this).style('fill', NODE_TEXT_COLOR)
                d3.select(this.parentElement)
                  .select('circle')
                  .transition()
                  .duration(100)
                  .style('fill', NODE_BACKGROUND_COLOR)
                  .attr('r', NODE_RADIUS)
              })
            return group
          },
          undefined,
          exit => exit.remove()
        )
    }
  }, [descendants, links, windowHeight, windowWidth])

  return (
    <svg
      style={{
        width: `${treeAreaWidth}px`,
        height: '100%',
      }}
      ref={d3Container}
      className={className}
    />
  )
}

export default Tree
