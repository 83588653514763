import React, { useEffect, useState } from 'react'
import useTreeInfo, { TreeArrayElement } from './state/Tree'
import Tree from './containers/Tree'
import ContextMenu from './containers/ContextMenu'
import UpdateValueWindow from './containers/UpdateValueWindow'
import RightPane from './containers/RightPane'
import Button from './components/Button'
import Toast, { ToastType } from './components/Toast'
import RightPaneStatusContext, {
  CopyStatus,
  GenerateStatus,
} from './contexts/RightPaneStatusContext'

const defaultValues = [
  1,
  2,
  3,
  null,
  5,
  6,
  7,
  null,
  null,
  8,
  9,
  10,
  null,
  111,
  122,
  133,
  null,
  14,
  15,
  null,
  16,
  17,
  null,
  null,
  18,
]

const TREE_CONTAINER = 'treeContainer'
const MAX_SCALE = 1.1
const MIN_SCALE = 0.7
const Helper: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(true)
  return isOpen ? (
    <div
      className="absolute bottom-0 left-0 px-5 py-3 mt-1 mb-3 ml-3 min-w-min 
    rounded flex flex-col justify-around bg-white"
    >
      <div className="flex justify-between">
        <span className="text-sm 2xl:text-lg font-bold">How to Use:</span>
        <span
          className="text-sm 2xl:text-lg underline cursor-pointer font-bold"
          onClick={() => setIsOpen(currentIsOpen => !currentIsOpen)}
        >
          Hide &times;
        </span>
      </div>
      <span className="text-xs 2xl:text-base text-black font-mono">
        Click on the node to edit the node value
      </span>
      <span className="text-xs 2xl:text-base text-black font-mono">
        Right click on the node to add children, delete node, or edit node value
      </span>
    </div>
  ) : (
    <Button
      className="absolute bottom-0 left-0 p-2 m-5 text-xl"
      onClick={() => setIsOpen(currentIsOpen => !currentIsOpen)}
    >
      Help &#9432;
    </Button>
  )
}

export interface ToastArgs {
  message: string
  toastType: ToastType
}

// https:stackoverflow.com/questions/43692714/how-to-zoom-container-div-and-all-its-contents-to-a-specific-size
const setZoom = (zoom: number, containerClassName: string) => {
  const container = document.getElementsByClassName(
    containerClassName
  )[0] as any
  if (!container) {
    throw new Error(`container with class name ${containerClassName}`)
  }
  const transformOrigin = [0, 0]
  const p = ['webkit', 'moz', 'ms', 'o'],
    s = 'scale(' + zoom + ')',
    oString = transformOrigin[0] * 100 + '% ' + transformOrigin[1] * 100 + '%'

  for (let i = 0; i < p.length; i++) {
    container.style[p[i] + 'Transform'] = s
    container.style[p[i] + 'TransformOrigin'] = oString
  }

  container.style['transform'] = s
  container.style['transformOrigin'] = 'center'
}

const App: React.FC = () => {
  // states for Toast
  const [toastArgs, setToastArgs] = useState<ToastArgs | null>(null)

  // states for ContextMenu and UpdateValueWindow
  const [clickedNodeId, setClickedNodeId] = useState<string>('')
  const [xPos, setXPos] = useState('0px')
  const [yPos, setYPos] = useState('0px')
  const [showMenu, setShowMenu] = useState(false)
  const [showUpdateValueWindow, setShowUpdateValueWindow] = useState(false)

  // states for the array that represents a level-order-traversal tree
  const [valueList, setValueList] = useState<TreeArrayElement[]>([])
  const [isRightPaneOpen, setIsRightPaneOpen] = useState<boolean>(true)

  const [copyStatus, setCopyStatus] = useState<CopyStatus>(CopyStatus.None)
  const [generateStatus, setGenerateStatus] = useState<GenerateStatus>(
    GenerateStatus.None
  )

  const [zoomScale, setZoomScale] = useState<number>(0.9)

  const {
    descendants,
    links,
    exportedString,
    addNode,
    removeNode,
    updateNode,
    getNodeValue,
    canAddChild,
  } = useTreeInfo(valueList, isRightPaneOpen)

  useEffect(() => {
    setValueList([...defaultValues])
  }, [])

  useEffect(() => {
    setZoom(zoomScale, TREE_CONTAINER)
  }, [zoomScale])

  const onZoomInClicked = () => {
    if (zoomScale < MAX_SCALE) {
      setZoomScale(pre => pre + 0.1)
    }
  }

  const onZoomOutClicked = () => {
    if (zoomScale > MIN_SCALE) {
      setZoomScale(pre => pre - 0.1)
    }
  }

  return (
    <RightPaneStatusContext.Provider
      value={{ copyStatus, setCopyStatus, generateStatus, setGenerateStatus }}
    >
      {toastArgs && (
        <Toast
          message={toastArgs.message}
          toastType={toastArgs.toastType}
          onCloseClick={() => setToastArgs(null)}
          className="absolute left-auto top-5"
        />
      )}
      <ContextMenu
        clickedNodeId={clickedNodeId}
        setClickedNodeId={setClickedNodeId}
        xPos={xPos}
        setXPos={setXPos}
        yPos={yPos}
        setYPos={setYPos}
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        setShowUpdateValueWindow={setShowUpdateValueWindow}
        addNode={addNode}
        removeNode={removeNode}
        canAddChild={canAddChild}
      />
      <UpdateValueWindow
        clickedNodeId={clickedNodeId}
        setClickedNodeId={setClickedNodeId}
        xPos={xPos}
        setXPos={setXPos}
        yPos={yPos}
        setYPos={setYPos}
        getNodeValue={getNodeValue}
        showUpdateValueWindow={showUpdateValueWindow}
        setShowUpdateValueWindow={setShowUpdateValueWindow}
        updateNodeValue={updateNode}
      />
      {/* full page container */}
      <div className="flex relative w-full h-full p-6">
        {/* left pane */}
        <div className="flex-1 flex flex-col m-4">
          <h1 className="text-2xl 2xl:text-3xl font-bold mb-4">
            Test Case Builder for Binary Tree Questions!
          </h1>
          <div className="flex-1 bg-gray-200 border-2 border-black relative">
            <Tree
              descendants={descendants}
              links={links}
              isRightPaneOpen={isRightPaneOpen}
              className={TREE_CONTAINER}
            />
            <Helper />
            {/* <a href="https://www.flaticon.com/free-icons/zoom-in" title="zoom in icons">Zoom in icons created by Gregor Cresnar - Flaticon</a> */}
            <button
              className="absolute top-5 right-5"
              onClick={onZoomInClicked}
            >
              <img
                className="w-7 h-7"
                src={process.env.PUBLIC_URL + '/zoom-in.png'}
              />
            </button>
            <button
              className="absolute top-16 right-5"
              onClick={onZoomOutClicked}
            >
              <img
                className="w-7 h-7"
                src={process.env.PUBLIC_URL + '/zoom-out.png'}
              />
            </button>
          </div>
        </div>
        <RightPane
          valueList={valueList}
          setValueList={setValueList}
          exportedString={exportedString}
          isOpen={isRightPaneOpen}
          setIsOpen={setIsRightPaneOpen}
          setToastArgs={setToastArgs}
        />
      </div>
    </RightPaneStatusContext.Provider>
  )
}

export default App
