import React, { useCallback, useContext, useEffect, useState } from 'react'
import Button from '../../components/Button'
import RightPaneStatusContext, {
  CopyStatus,
  GenerateStatus,
} from '../../contexts/RightPaneStatusContext'
import { FloatingWindowProps } from '../types'

export type UpdateValueWindowProp = {
  showUpdateValueWindow: boolean
  setShowUpdateValueWindow: React.Dispatch<React.SetStateAction<boolean>>
  getNodeValue: (id: string) => string
  updateNodeValue: (args: { nodeId: string; value: string }) => void
}

const UpdateValueWindow: React.FC<UpdateValueWindowProp & FloatingWindowProps> =
  ({
    clickedNodeId,
    setClickedNodeId,
    xPos,
    setXPos,
    yPos,
    setYPos,
    showUpdateValueWindow,
    getNodeValue,
    setShowUpdateValueWindow,
    updateNodeValue,
  }) => {
    const { setCopyStatus, setGenerateStatus } = useContext(
      RightPaneStatusContext
    )
    const [valueToUpdate, setValueToUpdate] = useState<string>('')

    const applyUpdate = useCallback(() => {
      updateNodeValue({
        nodeId: clickedNodeId,
        value: valueToUpdate.length === 0 ? 'EMPTY' : valueToUpdate,
      })
      setCopyStatus(CopyStatus.None)
      setGenerateStatus(GenerateStatus.None)
      setShowUpdateValueWindow(false)
    }, [
      clickedNodeId,
      setCopyStatus,
      setGenerateStatus,
      setShowUpdateValueWindow,
      updateNodeValue,
      valueToUpdate,
    ])

    const handleClick = useCallback(
      e => {
        const nodeId = e.path.find(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (elm: { dataset: { nodeId: string } }) => !!elm.dataset?.nodeId
        )?.dataset?.nodeId
        if (nodeId) {
          e.preventDefault()
          setClickedNodeId(nodeId)
          setValueToUpdate(getNodeValue(nodeId))
          setXPos(`${e.pageX}px`)
          setYPos(`${e.pageY}px`)
          setShowUpdateValueWindow(true)
        } else {
          setClickedNodeId('')
          setShowUpdateValueWindow(false)
        }
      },
      [
        setClickedNodeId,
        setShowUpdateValueWindow,
        setXPos,
        setYPos,
        getNodeValue,
      ]
    )

    const handleKeypress = useCallback(
      e => {
        if (e.key === 'Enter') {
          applyUpdate()
        }
      },
      [applyUpdate]
    )

    const handleKeyDown = useCallback(
      e => {
        if (e.key === 'Escape') {
          setShowUpdateValueWindow(false)
        }
      },
      [setShowUpdateValueWindow]
    )

    const handleContextMenu = useCallback(() => {
      setShowUpdateValueWindow(false)
    }, [setShowUpdateValueWindow])

    useEffect(() => {
      document.addEventListener('click', handleClick)
      document.addEventListener('keydown', handleKeyDown)
      document.addEventListener('contextmenu', handleContextMenu)
      return () => {
        document.removeEventListener('click', handleClick)
        document.removeEventListener('keydown', handleKeyDown)
        document.addEventListener('contextmenu', handleContextMenu)
      }
    }, [handleClick, handleContextMenu, handleKeyDown])
    return showUpdateValueWindow ? (
      <div
        className="absolute p-2 border-2 border-black border-solid z-10
        list-none rounded text-base 2xl:text-lg text-black flex items-center"
        style={{
          top: yPos,
          left: xPos,
        }}
        onClick={e => e.stopPropagation()}
      >
        <input
          className="h-11 leading-4 py-1 px-2 border-2 border-black border-solid rounded"
          type="text"
          onChange={e => setValueToUpdate(e.target.value)}
          onKeyPress={handleKeypress}
          autoFocus
          onFocus={e => e.target.select()}
          value={valueToUpdate}
        />
        <Button className="p-2 ml-3" onClick={applyUpdate}>
          Update
        </Button>
      </div>
    ) : null
  }

export default UpdateValueWindow
