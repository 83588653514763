import React, { useEffect, useState, useContext, FormEvent } from 'react'
import { ToastArgs } from '../../App'
import Button from '../../components/Button'
import RightPaneStatusContext, {
  CopyStatus,
  GenerateStatus,
} from '../../contexts/RightPaneStatusContext'
import { TreeArrayElement } from '../../state/Tree'

export interface IRightPane {
  valueList: TreeArrayElement[]
  setValueList: React.Dispatch<React.SetStateAction<TreeArrayElement[]>>
  exportedString: string
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  setToastArgs: React.Dispatch<React.SetStateAction<ToastArgs | null>>
}

const getInputString = (values: TreeArrayElement[]) =>
  `[${values.map(v => (v ? v : 'null')).join(',')}]`

const statusToProps = {
  [CopyStatus.Success]: { message: 'Copied', textColor: 'text-green-500' },
  [CopyStatus.Fail]: { message: 'Copy Failed', textColor: 'text-red-400' },
  [GenerateStatus.Success]: {
    message: 'Generated',
    textColor: 'text-green-500',
  },
  [GenerateStatus.Fail]: {
    message: 'Generate Failed',
    textColor: 'text-red-400',
  },
}

const RightPane: React.FC<IRightPane> = ({
  valueList,
  setValueList,
  exportedString,
  isOpen,
  setIsOpen,
  setToastArgs,
}) => {
  const [inputString, setInputString] = useState<string>('')
  const { copyStatus, setCopyStatus, generateStatus, setGenerateStatus } =
    useContext(RightPaneStatusContext)

  const onGenerateInputChange = (e: FormEvent<HTMLTextAreaElement>) => {
    setInputString(e.currentTarget.value)
    setGenerateStatus(GenerateStatus.None)
  }

  const onNewTreeClick = () => {
    // TODO: validate inputString, needs to be in the format of [1,2,3]
    const newValueList = inputString?.replace(/[\[\]]/g, '').split(',')
    if (!newValueList) {
      setGenerateStatus(GenerateStatus.Fail)
    } else {
      setValueList(newValueList)
      setGenerateStatus(GenerateStatus.Success)
    }
  }

  const onCopyClick = () => {
    navigator.clipboard.writeText(exportedString).then(
      () => {
        setCopyStatus(CopyStatus.Success)
      },
      () => {
        setCopyStatus(CopyStatus.Fail)
      }
    )
  }

  useEffect(() => {
    setInputString(getInputString(valueList))
  }, [valueList])

  return (
    // <div
    //   className={`relative h-full right-0 flex flex-col justify-evenly ${
    //     isOpen
    //       ? `w-1/4 border-l-2 p-3 border-solid border-gray-300`
    //       : `w-0 p-0 border-0`
    //   } `}
    // >
    // {/* <Button
    //   className="absolute top-1 -left-12 w-10 h-10 text-2xl"
    //   onClick={() => {
    //     setIsOpen(pre => !pre)
    //   }}
    // >
    //   {' '}
    //   {isOpen ? '>' : '<'}{' '}
    // </Button> */}
    <div
      className="h-full flex flex-col justify-evenly 
                 border-2 p-5 border-solid border-black"
    >
      <label
        htmlFor="generate-tree"
        className="font-mono text-base 2xl:text-lg text-black mb-1 z-10"
      >
        Input a level-order array:
      </label>
      <div className="flex-1 flex relative">
        <textarea
          id="generate-tree"
          className="flex-1 border-2 border-black rounded p-2 font-mono 
                     text-base 2xl:text-lg mb-2 w-full"
          onChange={onGenerateInputChange}
          value={inputString}
          spellCheck="false"
        />
        {generateStatus !== GenerateStatus.None && (
          <span
            className={`absolute bottom-4 right-4 font-bold 
            ${statusToProps[generateStatus].textColor}`}
          >
            {statusToProps[generateStatus].message} &#10003;
          </span>
        )}
      </div>
      <Button
        className="mb-8 text-base 2xl:text-lg h-10 z-10"
        onClick={onNewTreeClick}
      >
        Generate
      </Button>
      <label
        htmlFor="copy-tree"
        className="font-mono text-base 2xl:text-lg text-black mb-1 z-10"
      >
        Level-order array that is in sync with the tree on the left:
      </label>
      <div className="flex-1 flex relative">
        <textarea
          id="copy-tree"
          className="flex-1 border-2 border-black rounded p-2 font-mono text-base 2xl:text-lg mb-2 
        cursor-default focus:outline-none text-gray-500 bg-gray-200 w-full"
          value={exportedString}
          readOnly
          spellCheck="false"
        />
        {copyStatus !== CopyStatus.None && (
          <span
            className={`absolute bottom-4 right-4 font-bold 
            ${statusToProps[copyStatus].textColor}`}
          >
            {statusToProps[copyStatus].message} &#10003;
          </span>
        )}
      </div>
      <Button className="text-base 2xl:text-lg h-10 z-10" onClick={onCopyClick}>
        Copy
      </Button>
    </div>
  )
}

export default RightPane
