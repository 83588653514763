import * as d3 from 'd3'
import { useEffect, useState } from 'react'
import useDimensions from '../../hooks/useDimensions'
import { ONE_CHILD_TILT } from './constants'
import {
  TreeData,
  TreeInfo,
  NodeMap,
  TreeNode,
  TreeLink,
  CanAddChildArgs,
  TreeArrayElement,
} from './types'
import useTreeDataList from './useTreeDataList'

const stratifyTreeData: d3.StratifyOperator<TreeData> = d3
  .stratify<TreeData>()
  .id(d => `${d.id}`)
  .parentId(d => `${d.parentId}`)

type GetTreeInfoArgs = {
  treeDataList: TreeData[]
  treeAreaWidth: number
  treeAreaHeight: number
}

const getTreeInfo = ({
  treeDataList,
  treeAreaWidth,
  treeAreaHeight,
}: GetTreeInfoArgs): TreeInfo => {
  if (treeDataList.length === 0) {
    return { descendants: [], links: [], nodeMap: {} }
  }
  const intermediateDataStructure = stratifyTreeData(treeDataList)
  const treefyData: d3.TreeLayout<TreeData> = d3
    .tree<TreeData>()
    .size([treeAreaWidth, treeAreaHeight])
    .separation(function (a, b) {
      return (a.parent == b.parent ? 1 : 2) / a.depth
    })
  const rootNode = treefyData(intermediateDataStructure)
  const descendants = rootNode.descendants()
  const nodeMap: NodeMap = descendants.reduce((acc: NodeMap, node) => {
    acc[node.data.id] = node
    return acc
  }, {})
  const processedDescendants = descendants.map(node =>
    tiltNodeIfOneChild(node, nodeMap)
  )
  const links = rootNode.links()
  const processedLinks = links.map(link => {
    const { source, target } = link
    return {
      ...link,
      source: tiltNodeIfOneChild(source, nodeMap),
      target: tiltNodeIfOneChild(target, nodeMap),
    }
  })
  return {
    descendants: processedDescendants,
    links: processedLinks,
    nodeMap,
  }
}

/**
 * For node who only has one child, d3 will put the child on the same vertical line as the parent
 * So the user will NOT know if the child is a left child or a right child
 * Thus creating this method to manually move the child to the left or right a little bit
 * so that user can tell the difference
 * @param node the child node that might need to be tilt
 * @returns the same passed-in child node that has been tilt if it is the only child
 */
const tiltNodeIfOneChild = (node: TreeNode, nodeMap: NodeMap): TreeNode => {
  const parentNode = nodeMap[node.data.parentId]
  if (!parentNode) {
    return node
  }
  const childrenIds = parentNode.data.children
  const oneChild = childrenIds.filter(id => !!id).length === 1
  let tiltedX = node.x
  if (oneChild) {
    if (childrenIds[0] === node.data.id && node.x >= parentNode.x) {
      // left child
      tiltedX = parentNode.x - ONE_CHILD_TILT
    } else if (childrenIds[1] === node.data.id && node.x <= parentNode.x) {
      // right child
      tiltedX = parentNode.x + ONE_CHILD_TILT
    }
  }
  const tiltedNewNode = {
    ...node,
    x: tiltedX,
  }
  nodeMap[node.data.id] = tiltedNewNode
  return tiltedNewNode
}

const getIdsOfSubtree = (id: string, nodeMap: NodeMap, result: string[]) => {
  const node = nodeMap[id]
  if (node) {
    result.push(id)
    for (const childId of node.data.children) {
      if (childId) {
        getIdsOfSubtree(childId, nodeMap, result)
      }
    }
  }
}

const exportTree = (treeDataList: TreeData[], nodeMap: NodeMap): string => {
  if (!treeDataList || !treeDataList.length) {
    return ''
  }

  const list: string[] = []
  const rootTreeData = treeDataList.find(d => !d.parentId)
  const q: (string | null)[] = [rootTreeData?.id ?? null]

  while (q.length > 0) {
    const nodeId = q.shift() ?? null
    if (nodeId && nodeMap[nodeId]) {
      const node: TreeNode = nodeMap[nodeId]
      list.push(node.data.value)
      q.push(...node.data.children)
    } else {
      list.push('null')
    }
  }

  let lastIndex = list.length - 1
  for (let i = list.length - 1; i >= 0; i--) {
    if (list[i] !== 'null') {
      lastIndex = i
      break
    }
  }

  return `[${list.slice(0, lastIndex + 1).join(',')}]`
}

const useTreeInfo = (
  valueList: TreeArrayElement[],
  isRightPaneOpen: boolean
) => {
  const { treeDataList, setTreeDataList, highestId, setHighestId } =
    useTreeDataList(valueList)
  const { treeAreaWidth, treeAreaHeight } = useDimensions(isRightPaneOpen)
  const [descendants, setDescendants] = useState<TreeNode[]>([])
  const [links, setLinks] = useState<TreeLink[]>([])
  const [nodeMap, setNodeMap] = useState<NodeMap>({})
  const [exportedString, setExportedString] = useState<string>('')

  useEffect(() => {
    const {
      descendants: newDescendants,
      links: newLinks,
      nodeMap: newNodeMap,
    } = getTreeInfo({
      treeDataList,
      treeAreaWidth,
      treeAreaHeight,
    })
    setDescendants(newDescendants)
    setLinks(newLinks)
    setNodeMap(newNodeMap)
    setExportedString(exportTree(treeDataList, newNodeMap))
  }, [treeDataList, treeAreaWidth, treeAreaHeight])

  const addNode = (args: {
    parentId: string
    isLeft: boolean
    value: string
  }) => {
    const { parentId, isLeft, value } = args
    const newNodeTreeData: TreeData = {
      id: `${highestId}`,
      parentId,
      value,
      children: [null, null],
    }
    setHighestId(highestId + 1)
    const parentTreeData = treeDataList.find(d => d.id === parentId) as TreeData
    parentTreeData.children[isLeft ? 0 : 1] = newNodeTreeData.id
    const newTreeDataList = isLeft
      ? [newNodeTreeData, ...treeDataList]
      : [...treeDataList, newNodeTreeData]
    setTreeDataList(newTreeDataList)
  }

  /**
   * Remove a node from the tree. More specifically
   * 1. Get list of ids to remove
   * 2. Remove the element(s) from treeDataList, return new treeDataList so caller can use it to setTreeDataList()
   */
  const removeNode = (id: string) => {
    const mainNode = nodeMap[id]
    if (!mainNode) {
      // if id not found, then do nothing, caller will call setTreeDataList on the same old treeDataList
      return treeDataList
    }

    const parentNode = nodeMap[mainNode.data.parentId]
    if (parentNode) {
      const index = parentNode.data.children.indexOf(id)
      parentNode.data.children[index] = null
    }

    const idsToRemove: string[] = []
    getIdsOfSubtree(id, nodeMap, idsToRemove)
    const newTreeDataList = treeDataList.filter(
      e => !idsToRemove.includes(e.id)
    )
    setTreeDataList(newTreeDataList)
  }

  const updateNode = (args: { nodeId: string; value: string }) => {
    const { nodeId, value } = args
    const treeDataToUpdate = treeDataList.find(d => d.id === nodeId) as TreeData
    treeDataToUpdate.value = value
    const newTreeDataList = [...treeDataList]
    setTreeDataList(newTreeDataList)
  }

  const getNodeValue = (id: string): string => {
    return nodeMap[id]?.data?.value ?? 'null'
  }

  const canAddChild = ({ nodeId, addLeftChild }: CanAddChildArgs) => {
    const node: TreeNode = nodeMap[nodeId]
    if (!node) {
      return false
    }
    return (
      (addLeftChild && node.data.children[0] === null) ||
      (!addLeftChild && node.data.children[1] === null)
    )
  }

  return {
    descendants,
    links,
    nodeMap,
    exportedString,
    addNode,
    removeNode,
    updateNode,
    getNodeValue,
    canAddChild,
  }
}

export default useTreeInfo
