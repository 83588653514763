import React from 'react'

export interface IButton {
  className?: string
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

const Button: React.FC<IButton> = ({ className = '', onClick, children }) => {
  return (
    <button
      className={`
        font-mono
        bg-black 
        hover:bg-white 
        text-white 
        hover:text-black 
        border 
        border-black 
        rounded-xl
        ${className} 
      `}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default Button
