import React from 'react'
import Button from './Button'

export enum ToastType {
  Positive = 'Positive',
  Negative = 'Negative',
}

const toastTypeToStyles: {
  [toastType in ToastType]: { theme: string; button: string }
} = {
  [ToastType.Positive]: {
    theme: 'text-green-400 border-green-400',
    button: 'hover:bg-green-300 ',
  },
  [ToastType.Negative]: {
    theme: 'text-red-400 border-red-400',
    button: 'hover:bg-red-300 ',
  },
}

export interface IToast {
  className?: string
  message: string
  toastType: ToastType
  onCloseClick: React.MouseEventHandler<HTMLButtonElement>
}

const Toast: React.FC<IToast> = ({
  className = '',
  message,
  toastType,
  onCloseClick,
}) => {
  const { theme, button: buttonStyles } = toastTypeToStyles[toastType]
  return (
    <div
      className={`flex items-center p-2 mb-4 min-w-min 
       rounded bg-transparent border ${theme} ${className}`}
    >
      <div className="ml-3 text-lg font-mono flex-1">{message}</div>
      <Button
        className={`w-8 h-8 text-xl ml-3 ${theme} ${buttonStyles}`}
        onClick={onCloseClick}
      >
        &times;
      </Button>
    </div>
  )
}

export default Toast
