import React, { useCallback, useContext, useEffect } from 'react'
import RightPaneStatusContext, {
  CopyStatus,
  GenerateStatus,
} from '../../contexts/RightPaneStatusContext'
import { CanAddChildArgs } from '../../state/Tree'
import { FloatingWindowProps } from '../types'

export type ContextMenuProp = {
  addNode: (args: { parentId: string; isLeft: boolean; value: string }) => void
  removeNode: (id: string) => void
  showMenu: boolean
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>
  setShowUpdateValueWindow: React.Dispatch<React.SetStateAction<boolean>>
  canAddChild: (args: CanAddChildArgs) => boolean
}

const ContextMenu: React.FC<ContextMenuProp & FloatingWindowProps> = ({
  clickedNodeId,
  setClickedNodeId,
  xPos,
  setXPos,
  yPos,
  setYPos,
  showMenu,
  setShowMenu,
  setShowUpdateValueWindow,
  addNode,
  removeNode,
  canAddChild,
}) => {
  const { setCopyStatus, setGenerateStatus } = useContext(
    RightPaneStatusContext
  )

  const handleContextMenu = useCallback(
    e => {
      const nodeId = e.path.find(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (elm: { dataset: { nodeId: string } }) => !!elm.dataset?.nodeId
      )?.dataset?.nodeId
      if (nodeId) {
        e.preventDefault()
        setClickedNodeId(nodeId)
        setXPos(`${e.pageX}px`)
        setYPos(`${e.pageY}px`)
        setShowMenu(true)
      } else {
        setClickedNodeId('')
        setShowMenu(false)
      }
    },
    [setClickedNodeId, setShowMenu, setXPos, setYPos]
  )

  const handleClick = useCallback(() => {
    setShowMenu(false)
  }, [setShowMenu])

  const handleDoubleClick = useCallback(() => {
    showMenu && setShowMenu(false)
  }, [setShowMenu, showMenu])

  useEffect(() => {
    document.addEventListener('click', handleClick)
    document.addEventListener('contextmenu', handleContextMenu)
    document.addEventListener('dblclick', handleDoubleClick)
    return () => {
      document.removeEventListener('click', handleClick)
      document.removeEventListener('contextmenu', handleContextMenu)
      document.removeEventListener('dblclick', handleDoubleClick)
    }
  }, [handleClick, handleContextMenu, handleDoubleClick])

  return showMenu ? (
    <ul
      className="absolute border-2 border-black border-solid list-none rounded
       text-base 2xl:text-lg text-black cursor-pointer z-10 bg-white"
      style={{
        top: yPos,
        left: xPos,
      }}
    >
      {canAddChild({ nodeId: clickedNodeId, addLeftChild: true }) && (
        <li
          className="px-4 py-2 bg-black hover:bg-white font-mono text-white hover:text-black"
          onClick={() => {
            addNode({ parentId: clickedNodeId, isLeft: true, value: '1' })
            setCopyStatus(CopyStatus.None)
            setGenerateStatus(GenerateStatus.None)
          }}
        >
          Add Left Child
        </li>
      )}
      {canAddChild({ nodeId: clickedNodeId, addLeftChild: false }) && (
        <li
          className="px-4 py-2 bg-black hover:bg-white font-mono text-white hover:text-black"
          onClick={() => {
            addNode({ parentId: clickedNodeId, isLeft: false, value: '1' })
            setCopyStatus(CopyStatus.None)
            setGenerateStatus(GenerateStatus.None)
          }}
        >
          Add Right Child
        </li>
      )}
      <li
        className="px-4 py-2 bg-black hover:bg-white font-mono text-white hover:text-black"
        onClick={() => {
          removeNode(clickedNodeId)
          setCopyStatus(CopyStatus.None)
          setGenerateStatus(GenerateStatus.None)
        }}
      >
        Remove
      </li>
      <li
        className="px-4 py-2 bg-black hover:bg-white font-mono text-white hover:text-black"
        onClick={() => {
          setShowUpdateValueWindow(true)
          setClickedNodeId(clickedNodeId)
          setXPos(xPos)
          setYPos(yPos)
        }}
      >
        Update Value
      </li>
    </ul>
  ) : null
}

export default ContextMenu
