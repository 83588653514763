// https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
import { useState, useEffect } from 'react'

const getDimensions = (isRightPaneOpen: boolean) => {
  const { innerWidth: windowWidth, innerHeight: windowHeight } = window
  const treeAreaWidth = isRightPaneOpen ? 0.75 * windowWidth : windowWidth
  const treeAreaHeight = windowHeight * 0.6
  return {
    windowWidth,
    windowHeight,
    treeAreaWidth,
    treeAreaHeight,
  }
}

const useDimensions = (isRightPaneOpen: boolean) => {
  const [dimensions, setDimensions] = useState(getDimensions(isRightPaneOpen))

  useEffect(() => {
    setDimensions(getDimensions(isRightPaneOpen))
    const handleResize = () => {
      setDimensions(getDimensions(isRightPaneOpen))
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [isRightPaneOpen])

  return dimensions
}

export default useDimensions
