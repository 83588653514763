import { createContext } from 'react'

export enum CopyStatus {
  Success = 'CopySuccess',
  Fail = 'CopyFail',
  None = 'CopyNone',
}

export enum GenerateStatus {
  Success = 'GenerateSuccess',
  Fail = 'GenerateFail',
  None = 'GenerateNone',
}

interface RightPaneStatusProps {
  copyStatus: CopyStatus
  setCopyStatus: React.Dispatch<React.SetStateAction<CopyStatus>>
  generateStatus: GenerateStatus
  setGenerateStatus: React.Dispatch<React.SetStateAction<GenerateStatus>>
}

const RightPaneStatusContext = createContext<RightPaneStatusProps>({
  copyStatus: CopyStatus.None,
  setCopyStatus: () => console.log,
  generateStatus: GenerateStatus.None,
  setGenerateStatus: () => console.log,
})

export default RightPaneStatusContext
