import { useState, useEffect } from 'react'
import { TreeArrayElement, TreeData } from './types'

/**
 * Initialize a list of TreeData which will be fed into d3.stratify to calculate the position of the nodes
 * @param inputs list of string represents raw value(s) of nodes in the tree in level order
 * @returns a list of TreeData
 */
const initializeTreeDataList = (args: {
  inputs: string[]
}): { treeDataList: TreeData[]; highestId: number } => {
  const { inputs } = args
  let highestId = 1
  const treeDataList: TreeData[] = []
  if (!inputs || !inputs.length) {
    return { treeDataList: [], highestId }
  }
  const q: TreeData[] = []
  for (const value of inputs) {
    let nodeTreeData: TreeData | null
    nodeTreeData =
      value !== 'null'
        ? {
            id: `${highestId++}`,
            parentId: '',
            value,
            children: [],
          }
        : (nodeTreeData = null)
    if (q.length) {
      q[0].children.push(nodeTreeData?.id ?? null)
    }
    if (nodeTreeData) {
      if (q.length) {
        nodeTreeData.parentId = q[0].id
      }
      q.push(nodeTreeData)
    }
    if (q.length && q[0].children.length === 2) {
      treeDataList.push(q.shift() as TreeData)
    }
  }
  for (const remainNode of q) {
    if (remainNode.children.length === 0) {
      remainNode.children = [null, null]
    } else if (remainNode.children.length === 1) {
      remainNode.children.push(null)
    }
    treeDataList.push(remainNode)
  }

  return { treeDataList, highestId }
}

const useTreeDataList = (rawValues: TreeArrayElement[] = []) => {
  const [treeDataList, setTreeDataList] = useState<TreeData[]>([])
  const [highestId, setHighestId] = useState<number>(1)
  useEffect(() => {
    const { treeDataList, highestId: highestIdAfterInitialization } =
      initializeTreeDataList({
        inputs: rawValues.map(v => `${v}`),
      })
    setTreeDataList(treeDataList)
    setHighestId(highestIdAfterInitialization)
  }, [rawValues])
  return { treeDataList, setTreeDataList, highestId, setHighestId }
}

export default useTreeDataList
